<template>
    <div>
        <div class="breadcrumb">
            <h1>Registro Configuracion</h1>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-3">
                                <router-link :to="{ name: 'configuracion-create' }">
                                    <button class="btn th-custom-color">Nuevo</button>
                                </router-link>
                            </div>
                            <div class="col-sm-3 offset-sm-6">
                                <div class="text-right">
                                    <label class="switch switch-success mr-3">
                                        <input id="filterbyState"
                                        type="checkbox"
                                        :checked="estado"
                                        @change="listarConfiguracion"
                                        v-model="estado"
                                        >
                                        <span class="slider"></span>
                                        <span>{{ labelActivos }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-1">
                        <div class="col-sm-3">
                            <div class="btn-group">
                                <span class="pt-2 mr-1">Mostrar </span>
                                <b-form-select
                                    v-model="porPagina"
                                    :options="paginaOpciones">
                                </b-form-select>
                                <span class="pt-2 ml-1"> Registros</span>
                            </div>
                        </div>
                        <div class="col-sm-3 offset-sm-6 text-right">
                            <div class="form-group row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <input type="text"
                                    class="form-control"
                                    v-model="filter"
                                    @keyup="filtrarBusqueda"
                                    autocomplete="off"
                                    placeholder="Buscar"
                                    >
                                </div>
                            </div>
                        </div>
                        <div style="display: block; width: 100%">
                            <b-table
                                class="table table-striped table-bordered table-hover"
                                :fields="fields"
                                :busy="isLoading"
                                :items="tablaConfiguracions"
                                :per-page="porPagina"
                            >
                            <template #cell(editar)="items" v-if="estado">
                                <router-link
                                :to="{ name: 'configuracion-edit', params: {id: encrypt(items.item.id)}}"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Editar">
                                    <i class="nav-icon i-Pen-5 text-success font-weight-bold"
                                    title="editar" style="cursor: pointer;">
                                    </i>
                                </router-link>
                            </template>
                            <template #cell(eliminar)="data" v-if="estado">
                                <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                                style="cursor: pointer" title="eliminar"
                                @click="showDeleteConfirmation(data.item)"></i>
                            </template>
                            </b-table>
                            <div class="row">
                                <div class="col-sm-8"></div>
                                <div class="col-sm-4">
                                    <b-pagination
                                        v-model="paginaActual"
                                        :total-rows="totalFila"
                                        :per-page="porPagina"
                                        @input="listarConfiguracion"
                                        class="my-0 float-right"
                                    ></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TheModalDestroy/>
        </div>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import TheModalDestroy from '../components/theModalDestroy.vue';

export default {
    name: 'Index',
    components: {
        TheModalDestroy,
    },
    data() {
        return {
            delayTimeout: null,
        };
    },
    created() {
        this.listarConfiguracion();
    },
    methods: {
        ...call("configuracion/configuracionIndex", [
            "listarConfiguracion",
        ]),
        filtrarBusqueda() {
            const DELAY = 400;
            clearTimeout(this.delayTimeout);
            this.delayTimeout = setTimeout(this.listarConfiguracion, DELAY);
        },
        encrypt(id) {
            return btoa(id);
        },
        showDeleteConfirmation(item) {
            this.modalDestroyConfirmation = true;
            this.registro = item;
        },
    },
    computed: {
        labelActivos() {
        if (this.estado) {
            return 'Activos';
        }
        return 'Inactivos';
        },
        ...get('configuracion/configuracionIndex', [
            "fields",
            "tablaConfiguracions",
            "isLoading",
        ]),
        ...sync('configuracion/configuracionIndex', [
            "filter",
            "estado",
            "porPagina",
            'paginaActual',
            'paginaOpciones',
            'totalFila'
        ]),
        ...sync('configuracion/configuracionDestroy', [
            'modalDestroyConfirmation',
            'registro',
        ]),
    },
    beforeDestroy() {
        this.$store.dispatch('configuracion/configuracionIndex/reset');
    }
}
</script>