
<template>
    <b-modal class="modal-title" v-model="modalDestroyConfirmation" size="md" hide-footer>
        <template slot="modal-title">
            <h5 v-if="registro"><strong>Seguro que quieres eliminar el Registro - N° {{registro.numero}}?</strong></h5>
        </template>
        <form v-on:submit.prevent="eliminarConfiguracion" autocomplete="off">
            <div class="modal-body">
                <div class="row form-group">
                    <div class="col-sm-6">
                        <button class="btn btn-danger btn-block" type="submit">Eliminar</button>
                    </div>
                    <div class="col-sm-6">
                        <button class="btn btn-secondary btn-block" type="button" @click="modalDestroyConfirmation = false">Cancelar</button>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';

export default {
    name: 'theModalDestroy',
    data() {
        return {

        };
    },
    methods: {
        async eliminarConfiguracion() {
            const ELIMINAR_OK = await this.eliminar(this.registro);
            if (ELIMINAR_OK) {
                this.listarConfiguracion();
            }
        },
        ...call('configuracion/configuracionDestroy', ['eliminar']),
        ...call('configuracion/configuracionIndex', ['listarConfiguracion'])
    },
    computed: {
        ...get('configuracion/configuracionDestroy', [
            'registro',
        ]),
        ...sync('configuracion/configuracionDestroy', [
            'modalDestroyConfirmation',
        ]),
    },
    beforeDestroy() {
        this.$store.dispatch('configuracion/configuracionDestroy/reset');
    }
}
</script>